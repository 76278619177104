import {Component, OnInit, ViewEncapsulation, OnDestroy} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {AppConfigService} from './core/services/app-config.service';
import {UserPreferencesService} from './core/services/user-preferences.service';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {SessionTimeoutComponent} from './public/components/session-timeout/session-timeout.component';
import { FeatureFlagService } from './core/services/feature-flag.service';
import { ManageUserSharedService } from './core/services/manage-user-shared.service';

/** Base application component */
/** @ignore */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
/** App component that has implemented init method */
export class AppComponent implements OnInit, OnDestroy {
  /** nav api subscription */
  private navSub: Subscription;
  private isTimeoutWarning = false;
  featureFlagValue:boolean=false;
  /** Component instantiation */
  constructor(
    private readonly router: Router,
    private readonly appConfig: AppConfigService,
    private readonly userPreferencesService: UserPreferencesService,
    private readonly route: ActivatedRoute,
    private readonly idle: Idle,
    private readonly snackBar: MatSnackBar,
    private readonly featureFlagService: FeatureFlagService,
    private readonly managedUserService: ManageUserSharedService
  ) {
      this.router.events.subscribe(event => {
        let dataval=parseInt(sessionStorage.getItem('currentStep'), 10)
        console.log(dataval,(event instanceof NavigationEnd && event.url.includes('registration')))
        if ((event instanceof NavigationEnd && event.url.includes('changePassword')) || ((event instanceof NavigationEnd && event.url.includes('registration')) && dataval !== 0)) {
          this.idle.setIdleName('idle_warning');
          this.idle.setIdle(840); // setIdle for 14mins (14*60)
          this.idle.setTimeout(60);
          this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
          this.idle.onTimeoutWarning.subscribe(timeout => {
            if (!this.isTimeoutWarning) {
              if(event instanceof NavigationEnd && event.url.includes('registration')){
                this.snackBar.openFromComponent(SessionTimeoutComponent, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  data: 'Session Will be ended in a minute due to inactivity'
                });
              }else{
                this.snackBar.openFromComponent(SessionTimeoutComponent, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  data: 'You will be logged out in a minute due to inactivity'
                });
              }
              this.isTimeoutWarning = true;
            }
          });
          this.idle.onTimeout.subscribe(() => {
            if(event instanceof NavigationEnd && event.url.includes('registration')){
              this.snackBar.dismiss();
              this.isTimeoutWarning = false;
              sessionStorage.setItem('currentStep', JSON.stringify(0));
              sessionStorage.removeItem('completedStep');
              window.location.reload();
            }else{
              this.snackBar.dismiss();
              this.isTimeoutWarning = false;
              this.router.navigate(['login']);
            }
          });
          this.idle.onIdleEnd.subscribe(() => {
            this.isTimeoutWarning = false;
          });
          this.idle.watch();
        }
      });
  }

  /** Base init method */
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.appId) { // Get application id
        let domain: string;
        switch (params.appId) {
          case '1': {
            domain = String(this.appConfig.getConfig('movePro360'));
            break;
          }
          case '2': {
            domain = String(this.appConfig.getConfig('mobilifyHR'));
            break;
          }
          case '3': {
            domain = String(this.appConfig.getConfig('mobilifyUI'));
            break;
          }
          case '4': {
            domain = String(this.appConfig.getConfig('supplierPortal'));
            break;
          }
          case '5': {
            domain = String(this.appConfig.getConfig('benefitsBuilder'));
            break;
          }
          case '6': {
            domain = String(this.appConfig.getConfig('compensationServices'));
            break;
          }
          case '7' : {
            domain = String(this.appConfig.getConfig('atlas'));
            break;
          }
          case '8' : {
            domain = String(this.appConfig.getConfig('cartusOnline'));
            break;
          }
          case '9' : {
            domain = String(this.appConfig.getConfig('atlasReporting'));
            break;
          }
          case '10' : {
            domain = String(this.appConfig.getConfig('mobileApp'));
            break;
          }
        }
        this.userPreferencesService.setPreference({ // Less code to change for now (should really work off appId everywhere)
          Key: 'referrer',
          Value: domain,
          Persist: false
        });
        this.userPreferencesService.setPreference({
          Key: 'appId',
          Value: params.appId,
          Persist: false
        });
      }
      if (params.appName) {
        this.userPreferencesService.setPreference({
          Key: 'appName',
          Value: params.appName,
          Persist: false
        });
      }
      if (params.urlPath) {
        this.userPreferencesService.setPreference({
          Key: 'urlPath',
          Value: params.urlPath,
          Persist: false
        });
      }
    });
    this.navSub = this.router.events.subscribe(event => {
      if ((event instanceof NavigationEnd)) { // Google Analytics
        const pageTitle = event.urlAfterRedirects.replace(/(^\/)|(\/$)/g, ''); //remove slashes from the page title
        // (window as any).ga('set', 'page', `${window.location.pathname}#${event.urlAfterRedirects}`);
        // (window as any).ga('send', 'pageview');
        if ((<any>window).dataLayer) {
          (<any>window).dataLayer.push({
            event: 'pageview',
            page: {
              path: window.location.pathname + '#' + event.urlAfterRedirects
            }
          });
          // GTM Implementation for page navigation
          (<any>window).dataLayer.push({
            event: 'virtualPageview',
            pageUrl: window.location.href,
            pageTitle: pageTitle
          });
        }

      }
    });
    // if ((window as any).ga) {
    //   const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('google_analytics');
    //   (window as any).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
    // }
    
    this.gtmSubscription();
  }

  public gtmSubscription() {
    this.managedUserService.gtmData$.pipe().subscribe((data: any) => {
      if ((<any>window).dataLayer) {
        (<any>window).dataLayer.push({
          event: data.event,
          mainCategory: data.mainCategory, 
          mainCategoryValue: data.mainCategoryValue, 
          // eslint-disable-next-line
          event_name: data.event_name
        });
      }
    })
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.navSub) {
      this.navSub.unsubscribe();
    }
  }
}
