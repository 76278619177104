<div class="dialogMainContainer formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row"
        fxLayoutAlign="space-between end" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="dialogtitle">{{dialogTitle}}</div>
        <button type="button" class="button-icon" (click)="cancel()" title="close">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="middle-section">
        <mat-dialog-content>
            <ng-container *ngFor="let line of dialogLines">
                <p [innerHTML]="line"></p>
            </ng-container>
        </mat-dialog-content>
    </div>
    <mat-dialog-actions class="login-contain-btn">
        <button id="cancel" mat-raised-button mat-button type="button" (click)="cancel()">
            <mat-icon>cancel</mat-icon>
            <span>Cancel</span>
        </button>
        <button id="continue" mat-raised-button primary mat-button type="button" (click)="continue()">
            <mat-icon>check_circle</mat-icon>
            <span>Continue</span>
        </button>
    </mat-dialog-actions>
</div>